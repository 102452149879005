import { useTranslations } from "next-intl";
import { useCallback } from "react";
import { UseFormSetError, UseFormWatch } from "react-hook-form";

import { useUser } from "@shared/auth";

import * as constants from "src/constants";
import updateNewsletterSubscription from "src/lib/graphql/update-newsletter-subscription";

type FormInput = {
  firstName?: string;
  lastName?: string;
  email?: string;
  code?: string;
  password?: string;
  newsletter?: boolean;
};

type UseFormSubmissionHandlerArgs = {
  preferredLoginMethod: string;
  watch: UseFormWatch<FormInput>;
  user: ReturnType<typeof useUser>;
  setError: UseFormSetError<FormInput>;
  dryRun?: boolean;
};

/**
 * Defines react callbacks for the authentication form.
 * Encapsuling the logic in this hook makes the component logic
 * easier to grasp.
 */
export function useFormSubmissionHandler({
  preferredLoginMethod,
  watch,
  user,
  setError,
  dryRun = false,
}: UseFormSubmissionHandlerArgs) {
  const t = useTranslations(
    `${constants.MESSAGE_PREFIX}components.AuthenticationForm`,
  );

  /**
   * Uses the preferedLoginMethod to determine
   * which endpoint to use for authenticating the user.
   * @returns a promise with the response from the server
   */
  const submitFormRequest = useCallback(
    async (values: FormInput) => {
      switch (preferredLoginMethod) {
        case "password": {
          const response = await user.login({
            email: values.email,
            password: values.password,
          });
          if (response.ok && values.newsletter) {
            await updateNewsletterSubscription({
              active: true,
              source: "signup",
            });
          }
          return response;
        }
        case "passwordless": {
          const response = await user.login({
            email: values.email,
            code: values.code,
          });
          if (response.ok && values.newsletter) {
            await updateNewsletterSubscription({
              active: true,
              source: "signup",
            });
          }
          return response;
        }
        default: {
          return getAuthCode(user, values, dryRun);
        }
      }
    },
    [preferredLoginMethod, user, dryRun],
  );

  /**
   * Updates the form state based on the response from the server.
   * @param response the response from the server
   */
  const updateFormErrorState = useCallback(
    async (response: Response) => {
      if (!response.ok) {
        const body = await response.json();

        if (response.status === 429) {
          setError("email", {
            type: "custom",
            message: t("errors.tooManyRequests"),
          });
        }

        if (response.status === 400) {
          if (body?.message === "Password is wrong") {
            setError("password", {
              type: "custom",
              message: t("errors.passwordWrong"),
            });
          }

          if (body?.message === "Code is wrong") {
            setError("code", {
              type: "codeWrong",
              message: t("errors.codeWrong"),
            });
          }

          if (body?.message === "Code is expired") {
            setError("code", {
              type: "codeExpired",
              message: t("errors.codeExpired"),
            });
          }
        } else {
          setError("email", {
            type: "custom",
            message: `Error: ${response.status} – ${body?.message}`,
          });
        }
      }
    },
    [setError, t],
  );

  /**
   * Requests a new authentication code from the server.
   * This can be useful when the user has not received the code
   * or if the code has expired.
   * @returns a promise with the response from the server
   */
  const reRequestAuthCode = useCallback(async () => {
    const response = await getAuthCode(user, { email: watch("email") }, dryRun);

    await updateFormErrorState(response);
  }, [user, watch, dryRun, updateFormErrorState]);

  return {
    submitFormRequest,
    updateFormErrorState,
    reRequestAuthCode,
  };
}

useFormSubmissionHandler.messages = [
  `${constants.MESSAGE_PREFIX}components.AuthenticationForm`,
];

async function getAuthCode(
  user: ReturnType<typeof useUser>,
  values: FormInput,
  dry = false,
) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return user.requestAuthCode({
    firstName: values.firstName || undefined,
    lastName: values.lastName || undefined,
    email: values.email,
    newsletter: values.newsletter,
    timezone,
    dry,
  });
}
