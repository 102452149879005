import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { HiMail } from "react-icons/hi";
import * as z from "zod";

import { useUser } from "@shared/auth";
import { PoweredBy } from "@shared/components";
import NeedHelp from "@shared/components/src/NeedHelp";
import { Alert, Button, Checkbox, Input, Text } from "@shared/elements";

import { MESSAGE_PREFIX, paths } from "src/constants";
import * as constants from "src/constants";
import { getSearchParams } from "src/helpers";

import { useFormSubmissionHandler } from "./useFormSubmissionHandler";

type AuthenticationFormProps = {
  withPoweredBy?: boolean;
  withNeedHelp?: boolean;
  withAcceptTerms?: boolean;
  withName?: boolean;
  withNewsletterCheckbox?: boolean;
  autoSignup?: boolean;
};

/**
 * We handle signup and login at the same time.
 * We support paswordless login. But some users might have a password
 * and want to login with it. Therefore we need to handle both cases.
 *
 * Also keep in mind that the email and code fields could be prefilled by consumer of this component.
 *
 * The basic use case is the following
 * 1. User enters email and clicks on Continue button
 * 2. We send a request to signup and check what methods are available for this mail
 * 3. If the user has a password we show the password field otherwise we will show the code field after the successful request
 * 4. we make another request to login with the password or code
 */
export default function AuthenticationForm({
  withPoweredBy = false,
  withNeedHelp = true,
  withAcceptTerms = false,
  withNewsletterCheckbox = false,
  withName = false,
  /**
   * Defines if we should automatically create a new account for an unknown email.
   * We don't want to do this on screens that are clearly framed as login, so we can
   * give clear signals, that a user tries to login with an unknown email address.
   */
  autoSignup = false,
}: AuthenticationFormProps) {
  const t = useTranslations(
    `${constants.MESSAGE_PREFIX}components.AuthenticationForm`,
  );
  const router = useRouter();
  // nextjs useSearchParam needs a few renders to have the correct value
  // because of the static rendering, so we need to get it manually
  // in the first render to fill default form values
  const searchParams = useMemo(
    () => getSearchParams(router.asPath),
    [router.asPath],
  );
  const hasEmailAndCodeInUrl =
    searchParams.has("email") && searchParams.has("code");

  const user = useUser({
    redirectTo: (router.query.redirectTo as string) || paths.home,
    redirectIfFound: true,
    returnAfterAuthentication: false,
  });

  const [autoSubmit, setAutoSubmit] = useState(hasEmailAndCodeInUrl);
  const preferredLoginMethod = user.availableLoginMethods.includes("password")
    ? "password"
    : user.availableLoginMethods.includes("passwordless") ||
        hasEmailAndCodeInUrl
      ? "passwordless"
      : undefined;

  const schema = useMemo(
    () =>
      z.object({
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        email: z.string().email(t("errors.emailInvalid")),
        code: z.string().min(2, t("errors.codeRequired")).optional(),
        password: z.string().min(1, t("errors.passwordRequired")).optional(),
        newsletter: z.boolean().optional(),
      }),
    [t],
  );

  type TFormInput = z.infer<typeof schema>;

  const {
    register,
    watch,
    handleSubmit,
    formState,
    setError,
    setFocus,
    resetField,
  } = useForm<TFormInput>({
    mode: "onTouched",
    defaultValues: {
      firstName: undefined,
      lastName: undefined,
      email: searchParams.get("email") || undefined,
      code: searchParams.get("code") || undefined,
      password: undefined,
      newsletter: false,
    },
    resolver: zodResolver(schema),
  });

  // Autofocus the correct field
  useEffect(() => {
    // As soon as we know the preferred login method we can focus the correct field
    if (preferredLoginMethod === "password") {
      setFocus("password");
    } else if (preferredLoginMethod === "passwordless") {
      setFocus("code");
    } else {
      // If we don't know the preferred login method we focus name if it's present or the email field
      if (withName) {
        setFocus("firstName");
      } else {
        setFocus("email");
      }
    }
  }, [preferredLoginMethod, setFocus, withName]);

  const email = watch("email");
  const { errors: formErrors, isSubmitting } = formState;

  // All the submission logic is condensed into this hook
  const { submitFormRequest, updateFormErrorState, reRequestAuthCode } =
    useFormSubmissionHandler({
      preferredLoginMethod,
      watch,
      user,
      setError,
      dryRun: !autoSignup,
    });

  /**
   * This onSubmit function does a lot of magic.
   * Based on the preferredLoginMethod we either
   * - request a code
   * - login with a password
   * - login with a code
   */
  const onSubmit = useCallback(
    async (values: TFormInput) => {
      const response = await submitFormRequest(values);
      await updateFormErrorState(response);
    },
    [updateFormErrorState, submitFormRequest],
  );

  // User can request a new code if the code is wrong or expired
  const onRequestNewCode = useCallback(() => {
    resetField("code");
    setFocus("code");
    reRequestAuthCode();
  }, [reRequestAuthCode, setFocus, resetField]);

  const onClear = useCallback(() => {
    // email and code can be passed as query params
    // eg. from login to signup or magic login links
    if (searchParams.has("email") || searchParams.has("code")) {
      searchParams.delete("email");
      searchParams.delete("code");
      router.push({
        pathname: router.pathname,
        search: `${searchParams}`,
      });
    }

    // reset login info as availableLoginMethods and accountCreationType
    user.resetLoginInfo();

    // reset form fields with new defaultValue
    // because the initial value could not be empty
    // eg. redirect from login to signup or magic login links
    resetField("email", { defaultValue: "" });
    resetField("code", { defaultValue: undefined });
    resetField("password", { defaultValue: undefined });

    setFocus("email");
  }, [resetField, router, searchParams, setFocus, user]);

  useEffect(() => {
    if (autoSubmit) {
      setAutoSubmit(false);
      onSubmit({
        email: searchParams.get("email"),
        code: searchParams.get("code"),
      });
    }
  }, [autoSubmit, onSubmit, searchParams]);

  /**
   * The error message of the code field is a bit more complex.
   * Therefore, we encapsulate it as a component.
   */
  const CodeErrorInfo = () => {
    // Check if its error message needs a resend code button
    if (
      formErrors.code?.type === "codeWrong" ||
      formErrors.code?.type === "codeExpired"
    ) {
      type CodeType = "codeWrong" | "codeExpired";

      return (
        <>
          {t.rich(`errors.${formErrors.code.type as CodeType}`, {
            resendCodeLink: (children) => (
              <Button
                className="text-primary-600 hover:text-primary-500 py-0"
                variant="text"
                onClick={onRequestNewCode}
              >
                {children}
              </Button>
            ),
          })}
        </>
      );
    }

    return <>{formErrors.code?.message}</>;
  };

  return (
    <>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)} noValidate>
        {withName && preferredLoginMethod === undefined && (
          <>
            <Input
              label={t("labelFirstName")}
              {...register("firstName")}
              error={formErrors.firstName?.message}
              disabled={preferredLoginMethod !== undefined}
            />
            <Input
              label={t("labelLastName")}
              {...register("lastName")}
              error={formErrors.lastName?.message}
              disabled={preferredLoginMethod !== undefined}
            />
          </>
        )}
        <Input
          label={t("labelEmail")}
          autoComplete="email"
          type="email"
          {...register("email")}
          onClear={onClear}
          error={
            formErrors.email?.message ||
            (user.accountCreationType === "non-existing" &&
              t.rich("errors.emailNotExisting", {
                link: (string) => (
                  <Text
                    as={Link}
                    size="sm"
                    color="primary"
                    // @ts-ignore TODO: fix typing in Text component to allow extending props depending on the as prop
                    href={`${paths.signup}?${
                      (searchParams.set("email", email), searchParams)
                    }`}
                  >
                    {string}
                  </Text>
                ),
              })) ||
            undefined
          }
        />

        {preferredLoginMethod === "password" && (
          <Input
            label={t("labelPassword")}
            name="password"
            type="password"
            {...register("password")}
            error={formErrors.password?.message}
          />
        )}

        {preferredLoginMethod === "passwordless" && (
          <>
            <Alert color="primary" Icon={HiMail}>
              {t("codeSentAlert")}
            </Alert>
            <Input
              label={t("labelCode")}
              placeholder={t("placeholderCode")}
              type="text"
              {...register("code")}
              error={formErrors.code?.message && <CodeErrorInfo />}
            />
          </>
        )}

        {(withNewsletterCheckbox || user.accountCreationType === "new") && (
          <Checkbox
            name="newsletter"
            label={t("newsletter")}
            {...register("newsletter")}
          />
        )}

        <Button
          type="submit"
          variant="primary"
          className="w-full"
          inProgress={isSubmitting}
          disabled={isSubmitting}
          // Google Tanslate breaks our app if we try to change the text of an existing React node, instead of
          // replacing it with a new one. Therefore, we can't use one button component with alternating labels
          // based on the preferredLoginMethod. Instead, we need to use a new button component for each case.
          // Changing the key of the button component forces React to replace the old button with a new one. This
          // seems to be fixing the issue effectively.
          // See this discussion for more context: https://github.com/facebook/react/issues/11538
          key={`${preferredLoginMethod || "email"}-submit`}
        >
          {preferredLoginMethod === "password" && t("btnLoginPassword")}
          {preferredLoginMethod === "passwordless" && t("btnLoginCode")}
          {!preferredLoginMethod && t("btnLoginEmail")}
        </Button>
        {preferredLoginMethod === "password" && (
          <Text as="div" size="sm">
            <Link
              className="font-medium text-primary-600 hover:text-primary-500"
              href={constants.paths.passwordRecovery}
            >
              {t("forgotPassword")}
            </Link>
          </Text>
        )}
      </form>
      <hr className="my-8" />
      {withPoweredBy && <PoweredBy />}
      {withAcceptTerms && (
        <Text as="p" size="xs" color="muted" className="mt-5">
          {t.rich("termsNote", {
            terms: (string) => (
              <Text
                as={Link}
                size="xs"
                color="primary"
                // @ts-ignore TODO: fix typing in Text component to allow extending props depending on the as prop
                href="https://www.picter.com/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                {string}
              </Text>
            ),
            privacy: (string) => (
              <Text
                as={Link}
                size="xs"
                color="primary"
                // @ts-ignore TODO: fix typing in Text component to allow extending props depending on the as prop
                href="https://www.picter.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {string}
              </Text>
            ),
          })}
        </Text>
      )}
      {withNeedHelp && <NeedHelp className="mt-6" />}
    </>
  );
}

AuthenticationForm.messages = [
  `${MESSAGE_PREFIX}components.AuthenticationForm`,
  ...PoweredBy.messages,
  ...NeedHelp.messages,
  ...useFormSubmissionHandler.messages,
];
