import classNames from "clsx";
import Image from "next/image";
import { useTranslations } from "next-intl";

import { Text } from "@shared/elements";

import { MESSAGE_PREFIX } from "src/constants";

type BrandsGridDisplayProps = {
  className?: string;
};

export default function BrandsGridDisplay({
  className,
}: BrandsGridDisplayProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.BrandsGridDisplay`);
  return (
    <div
      className={classNames(
        "hidden lg:flex flex-1 flex-col min-h-screen justify-center bg-gradient-to-r from-sky-200 to-violet-300 p-12",
        className,
      )}
    >
      <div className="w-full lg:max-w-none flex justify-center align-center">
        <Image
          src="/assets/logo-grid-perspective.svg"
          width={601}
          height={600}
          alt="Brands using Picter"
        />
      </div>
      <div className="text-center mt-6">
        <Text
          className="text-indigo-900"
          weight="bold"
          color="custom"
          size="3xl"
        >
          {t("brandsAltText")}
        </Text>
      </div>
    </div>
  );
}

BrandsGridDisplay.messages = [`${MESSAGE_PREFIX}components.BrandsGridDisplay`];
