import { graphqlFetch } from "@shared/hooks";

import {
  TRootMutationTypeUpdateNewsletterSubscriptionArgs,
  TUpdateNewsletterSubscription,
  UpdateNewsletterSubscription,
} from "__generated__/types";

export default function updateNewsletterSubscription(
  values: TRootMutationTypeUpdateNewsletterSubscriptionArgs,
): Promise<TUpdateNewsletterSubscription> {
  return graphqlFetch(
    JSON.stringify({
      query: UpdateNewsletterSubscription,
      variables: {
        active: values.active,
        source: values.source ?? undefined,
      },
    }),
  );
}
